var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-spacing" },
    [
      _c("BreadCrumb", { attrs: { items: _vm.itemsBreadCrumb } }),
      _c(
        "div",
        {
          staticClass: "d-flex align-end justify-space-between flex-wrap my-8",
        },
        [
          _c("HeaderTitle", {
            attrs: { title: _vm.title, subtitle: _vm.subtitle },
          }),
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "d-flex flex-row-reverse" },
                [
                  _vm.hasAddButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-primary",
                          on: { click: _vm.clickAdd },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-plus"),
                          ]),
                          _vm._v(_vm._s(_vm.addButtonText)),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasConfigButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-secondary mr-4",
                          on: { click: _vm.clickConfig },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-cog-outline"),
                          ]),
                          _vm._v(_vm._s(_vm.configButtonText)),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.hasUpdateInfo
                ? _c("UpdateInfo", {
                    attrs: { lastProcessData: _vm.lastProcessData },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }