<template>
  <div class="header-spacing">
    <BreadCrumb :items="itemsBreadCrumb"></BreadCrumb>
    <div class="d-flex align-end justify-space-between flex-wrap my-8">
      <HeaderTitle :title="title" :subtitle="subtitle" />
      <div>
        <div class="d-flex flex-row-reverse">
          <v-btn v-if="hasAddButton" class="btn-ag-primary" @click="clickAdd"
            ><v-icon left>mdi-plus</v-icon>{{ addButtonText }}</v-btn
          >
          <v-btn v-if="hasConfigButton" class="btn-ag-secondary mr-4" @click="clickConfig"
            ><v-icon left>mdi-cog-outline</v-icon>{{ configButtonText }}</v-btn
          >
        </div>
        <UpdateInfo v-if="hasUpdateInfo" :lastProcessData="lastProcessData" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',

  components: {
    BreadCrumb: () => import('./BreadCrumb.vue'),
    HeaderTitle: () => import('./HeaderTitle.vue'),
    UpdateInfo: () => import('./UpdateInfo.vue'),
  },

  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: false,
      type: String,
      default: '',
    },
    itemsBreadCrumb: {
      required: true,
      type: Array,
    },
    hasAddButton: {
      required: true,
      type: Boolean,
      default: false,
    },
    addButtonText: {
      required: false,
      type: String,
      default: 'Adicionar',
    },
    hasConfigButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    configButtonText: {
      required: false,
      type: String,
      default: 'Configurações',
    },
    hasUpdateInfo: {
      required: true,
      type: Boolean,
      default: false,
    },
    lastProcessData: {
      required: false,
      type: String,
    },
  },

  methods: {
    clickAdd(e) {
      this.$emit('clickAdd', e);
    },
    clickConfig(e) {
      this.$emit('clickConfig', e);
    },
  },
};
</script>
<style scoped>
.header-spacing {
  padding-top: 96px;
}
</style>
